<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('employees.create')"
                   to="/employees/employees/create"
                   class="btn btn-primary font-weight-bolder"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('employees.add_employee') }}
      </router-link
      >
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="name">{{ $t('name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="email">{{ $t('employees.email') }}</label>
              <input v-model="filters.email" type="text" id="email" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="from_date">{{ $t('employees.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="to_date">{{ $t('employees.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label>{{ $t('employees.level') }}</label>
              <select name="" id="level" v-model="filters.level" class="custom-select">
                <option v-for="row in employment_levels" :value="row.id" :key="row.id">
                  {{ row.title }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label>{{ $t('employees.type') }}</label>
              <select name="" id="type" v-model="filters.type" class="custom-select">
                <option v-for="row in employment_types" :value="row.id" :key="row.id">
                  {{ row.title }}
                </option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-4 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <!-- <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('employees.employees_management')}}
            <span class="text-muted pt-2 font-size-sm d-block"
              >{{ $t(('employees.employees') )}}</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link v-if="$can('employees.create')"
            to="/employees/employees/create"
            class="btn btn-primary font-weight-bolder"
            ><v-icon>mdi-plus</v-icon>{{ $t('employees.add_employee')}}</router-link
          >
        </div>
      </div> -->
      <div class="card-body">

        <!-- Filter -->
        <div>
          <div class="row justify-content-end p-4">
            <custom-export-data :data-list="dataList" :fields="json_fields" :file-name="$t('employees.employees_management')"></custom-export-data>
          </div>

        </div>
        <!-- End Filter -->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('employees.change_status')"
                size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                v-model="props.row.is_active"
                :name="'check-button'+props.row.id"
                switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                size="lg"
                v-model="props.row.is_active"
                :name="'check-button'+props.row.id"
                switch :key="props.row.id">
            </b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">
            <v-btn v-if="$can('employees.update')" icon color="pink" :to="`employees/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
            </v-btn>
            <v-icon small class="text-danger" v-if="$can('employees.delete')" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            <b-dropdown id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
              <router-link v-if="$can('employees.attachment_notes')" class="dropdown-item" :to="`/sales/attachment-notes/create/${props.row.id}/${'Employee'}`">
                {{ $t('MENU.add_notes_attachments') }}
              </router-link>
            </b-dropdown>
          </template>

        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-employees",
  components: {},
  data() {
    return {
      mainRoute: 'employee/employees',
      routeChangeStatus: 'employee/employee/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      dataList: [],
      filters: {
        name: '',
        email: '',
        from_date: '',
        to_date: '',
        level: '',
        type: '',
        is_active: '',
      },
      columns: ['#','full_name', 'email', 'mobile', 'start_date', 'created_at', 'level', 'type', 'status', 'actions'],
      data: [],
      employment_levels: [],
      employment_types: [],
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],

    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('employees.full_name')] = 'full_name';
      fields[this.$t('employees.email')] = 'email';
      fields[this.$t('employees.mobile')] = 'mobile';
      fields[this.$t('employees.start_date')] = 'start_date';
      fields[this.$t('employees.level')] = 'level';
      fields[this.$t('employees.type')] = 'type';
      fields[this.$t('employees.state')] = 'state';
      fields[this.$t('employees.notes')] = 'notes';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          full_name: that.$t('employees.full_name'),
          email: that.$t('employees.email'),
          mobile: that.$t('employees.mobile'),
          start_date: that.$t('employees.start_date'),
          created_at: that.$t('created_at'),
          level: that.$t('employees.level'),
          type: that.$t('employees.type'),
          status: that.$t('status'),
          actions: that.$t('global.actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params})

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.employees_management")}]);
    this.getEmploymentTypes();
    this.getEmploymentLevels();
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },

    resetFilter() {
      this.filters.name = '';
      this.filters.email = '';
      this.filters.mobile = '';
      this.filters.from_date = '';
      this.filters.to_date = '';
      this.filters.level = '';
      this.filters.type = '';
      this.filters.status = '';
      this.filters.is_active = '';
      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getEmploymentTypes() {
      ApiService.get(this.mainRouteDependency + "/employment_type").then((response) => {
        this.employment_types = response.data.data;
      });
    },
    getEmploymentLevels() {
      ApiService.get(this.mainRouteDependency + "/employment_level").then((response) => {
        this.employment_levels = response.data.data;
      });
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
  },
};
</script>
